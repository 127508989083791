
html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: "Inconsolata", sans-serif;
  min-height: 100vh;
  color: #DDDEE4;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

#root {
  min-height: 100%;
  position: relative;
}

// MENU
.cross input:checked+svg  {
  .line--1,
  .line--3 {
    stroke-dasharray: 22.627416998 127.64183;
  }

  .line--2 {
    stroke-dasharray: 0 127.64183;
  }
}

.menu {
  .line--1,
  .line--3 {
    stroke-dasharray: 24 126.64183044433594;
  }

  .line--2 {
    stroke-dasharray: 24 70;
  }
  
  input:checked+svg {
    .line--1,
    .line--3 {
      stroke-dashoffset: -94.1149185097;
    }
    .line--2 {
      stroke-dashoffset: -50;
    }
  }

  path {
    fill: none;
    stroke: #ffffff;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 24 127.64183;
    stroke-dashoffset: -38;
    transition: all .8s cubic-bezier(.645, .045, .355, 1);
  }
}

// BORDER RADIUS
.module {
  position: relative;
}
.module-border {
  position: absolute;
  overflow: visible;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  fill: none;
  stroke: url(#grad);
  stroke-width: 1.6px;
}
#grad stop:nth-child(1) {
  stop-color: #AAB7E5;
}
#grad stop:nth-child(2) {
  stop-color: #8DECE6;
}

/* customize scroll bar */
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}


// pagination
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center;
}

.page-link {
  position: relative;
  display: block;
  color: black;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: white;
  background-color: #ABB7E5;
  border-color: #ABB7E5;
  cursor: pointer;
}
.page-link:focus {
  z-index: 3;
  color: black;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(171, 183, 229, 0.2);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #8BF3E7;
  border-color: #8BF3E7;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}